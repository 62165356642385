var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-create-page"},[_c('PageTitle',{attrs:{"items":{
      title: '管理者',
      subTitle: '管理者登録',
      back: {
        action: function () {
          _vm.$router.push({ name: 'AdminListing' })
        }
      }
    }}}),_c('v-card',{staticClass:"pa-5"},[_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('v-container',{staticClass:"container-main py-4"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"8"}},[_c('v-col',{staticClass:"mb-n5",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("管理者名")]),_c('span',{staticClass:"error--text ml-2 font-12px"},[_vm._v("必須")])]),_c('validation-provider',{attrs:{"name":_vm.fields.name.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","dense":"","placeholder":"管理者名"},model:{value:(_vm.fields.name.value),callback:function ($$v) {_vm.$set(_vm.fields.name, "value", $$v)},expression:"fields.name.value"}})]}}])})],1),_c('v-col',{staticClass:"mb-n5",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("メールアドレス")]),_c('span',{staticClass:"error--text ml-2 font-12px"},[_vm._v("必須")])]),_c('validation-provider',{attrs:{"name":_vm.fields.email.name,"rules":"required|email|only_english_lang_allowed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-email","error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","dense":"","placeholder":"メールアドレス"},model:{value:(_vm.fields.email.value),callback:function ($$v) {_vm.$set(_vm.fields.email, "value", $$v)},expression:"fields.email.value"}})]}}])})],1),_c('v-col',{staticClass:"mb-n5",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("パスワード 英数字8文字以上")]),_c('span',{staticClass:"error--text ml-2 font-12px"},[_vm._v("必須")])]),_c('validation-provider',{attrs:{"name":_vm.fields.password.name,"vid":"confirmation","rules":"required|verify_password|min:8|only_english_lang_allowed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","type":"password","autocomplete":"new-password","dense":"","placeholder":"パスワード"},model:{value:(_vm.fields.password.value),callback:function ($$v) {_vm.$set(_vm.fields.password, "value", $$v)},expression:"fields.password.value"}})]}}])})],1),_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',{staticClass:"ml-2"},[_vm._v("パスワード(確認用)")]),_c('span',{staticClass:"error--text ml-2 font-12px"},[_vm._v("必須")])]),_c('validation-provider',{attrs:{"name":_vm.fields.password_confirmed.name,"rules":"required|verify_password|min:8|only_english_lang_allowed|password_confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","type":"password","autocomplete":"new-password","dense":"","placeholder":"パスワード"},model:{value:(_vm.fields.password_confirmed.value),callback:function ($$v) {_vm.$set(_vm.fields.password_confirmed, "value", $$v)},expression:"fields.password_confirmed.value"}})]}}])})],1),_c('v-col',{staticClass:"-mt-1",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"admin-footer-action text-center mt-10"},[_c('v-btn',{staticClass:"btn-primary white--text",attrs:{"type":"submit","loading":_vm.loading,"max-width":"188px","width":"100%","height":"35"},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v("登録")])],1)])],1)],1)],1)],1)],1)],1),_c('SuccessModel',{attrs:{"text":"管理者を登録しました。","buttonText":"管理者一覧へ戻る","routeName":"AdminListing","dialog":_vm.dialog.success},on:{"closeModel":function($event){_vm.dialog.success = false}}}),_c('SimpleModel',{attrs:{"text":"この管理者情報を登録してよろしいですか？","dialog":_vm.dialog.submit,"submitButtonText":"登録する"},on:{"submitSuccess":function($event){return _vm.submit()},"closeModel":function($event){_vm.dialog.submit = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }